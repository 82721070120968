
var rippleEffect = (function() {
    function rippleEffect(event) {
       var 
           time = 2000
          ,self = this
          ,rect = self.getBoundingClientRect()
          ,x = event.clientX - rect.left
          ,y = event.clientY - rect.top
          ,circle = document.createElement("div")
       ;
       
       circle.style.top = y + "px";
       circle.style.left = x + "px";
       circle.className = "ripple animate";
       self.appendChild(circle);
 
       setTimeout(function() {
          self.removeChild(circle);
       }, time);
    }
 
    var targets = document.getElementsByClassName("ripple-effect")
       ,l = targets.length
       ,i;
    
    for (i=0; i<l; i++) {
       targets[i].addEventListener('mousedown', rippleEffect, false);
    }   
 }());
 

 const burger = document.querySelector('.header__mobile__burger');
 const popup = document.querySelector('.header__mobile__popup');
 const header = document.querySelector('.header__mobile');
 const lineOne = document.querySelector('.header__mobile__burger__line_one');
 const lineTwo = document.querySelector('.header__mobile__burger__line_two');
 const lineThree = document.querySelector('.header__mobile__burger__line_three');

 

   burger.addEventListener('click', ()=> {
    popup.classList.toggle('header__mobile__show');
    header.classList.toggle('mobile-bg');
    lineOne.classList.toggle('header__mobile__burger__line_one_rotate');
    lineTwo.classList.toggle('header__mobile__burger__line_two_rotate');
    lineThree.classList.toggle('header__mobile__burger__line_three_rotate');

 }),
 
 $('.slider').slick({
   infinite: true,
   slidesToShow: 1,
   slidesToScroll: 1,
   prevArrow: "<img src='https://svgshare.com/i/Edr.svg' class='prev' alt='1'>",
   nextArrow: "<img src='https://svgshare.com/i/EeS.svg' class='next' alt='2'>"
 });
 
	
// собираем все якоря; устанавливаем время анимации и количество кадров
const anchors = [].slice.call(document.querySelectorAll('a[href*="#"]')),
      animationTime = 300,
      framesCount = 110;

anchors.forEach(function(item) {
  // каждому якорю присваиваем обработчик события
  item.addEventListener('click', function(e) {
    // убираем стандартное поведение
    e.preventDefault();
    popup.classList.toggle('header__popup__show');
    // для каждого якоря берем соответствующий ему элемент и определяем его координату Y
    let coordY = document.querySelector(item.getAttribute('href')).getBoundingClientRect().top;
    
    // запускаем интервал, в котором
    let scroller = setInterval(function() {
      // считаем на сколько скроллить за 1 такт
      let scrollBy = coordY / framesCount;
      
      // если к-во пикселей для скролла за 1 такт больше расстояния до элемента
      // и дно страницы не достигнуто
      if(scrollBy > window.pageYOffset - coordY && window.innerHeight + window.pageYOffset < document.body.offsetHeight) {
        // то скроллим на к-во пикселей, которое соответствует одному такту
        window.scrollBy(0, scrollBy);
      } else {
        // иначе добираемся до элемента и выходим из интервала
        window.scrollTo(0, coordY);
        clearInterval(scroller);
      }
    // время интервала равняется частному от времени анимации и к-ва кадров
    }, animationTime / framesCount);
  });
});
